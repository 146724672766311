import  {Parsing,slideUp,slideDown} from './pure.js'
require('fslightbox')

const News = async () => {

    let newsItems = document.querySelectorAll('.news__item')
	let newsModal = document.querySelector('#newsModal')
	let newsModalTitle = document.querySelector('#newsModalTitle')
	for(let newsItem of newsItems){
		newsItem.addEventListener('click',(e)=>{
			let origin = location.origin
			let url = origin + e.currentTarget.dataset.url
			newsModalTitle.innerHTML = ''
			newsModal.querySelector('.modal-body').innerHTML = ''
			fetchNews(url).then((data) => {

				newsModal.querySelector('.modal-content').classList.add('preload')
				data = new DOMParser().parseFromString(data,'text/html')

				return data

			})
			.then((data) => {
				newsModalTitle.innerHTML = data.querySelector('.page-title').innerHTML
				 newsModal.querySelector('.modal-body')
				.innerHTML = data.querySelector('.post__body').innerHTML
				insertScript('https://yastatic.net/share2/share.js',newsModal.querySelector('.modal-body'))
				refreshFsLightbox()

				})

			.then(() => {
				setTimeout(() => {
					newsModal.querySelector('.modal-content').classList.remove('preload')
				}, 1000);

			})

		})
	}
	if(newsModal.querySelector('.modal-body').clientHeight > window.screen.availHeight){

	newsModal.querySelector('.modal-body').addEventListener('scroll',(e)=>{
			let header = e.target.previousElementSibling
			let footer = newsModal.querySelector('.modal-footer')
			if(e.target.scrollTop > 0){
				header.classList.add('reduce')
			}else{
				header.classList.remove('reduce')
				return false
			}
		})
	}

	async function fetchNews(url) {

			let res = await fetch(url,{
			method:'GET',
			headers:{
				"x-requested-with":"XMLHttpRequest"
				}
			})
			if (!res.ok) {
			throw Error(response.statusText)
			}
			return await res.text()

	}


const insertScript = (script,tag)=>{
        const js_script = document.createElement('script')
        js_script.type = "text/javascript"
        js_script.src = script

        tag.appendChild(js_script)

    }

}

export default News
