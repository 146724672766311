import WinBox from 'winbox/src/js/winbox.js'
import { slideUp,slideDown } from "./pure.js";
const captcha = 'https://www.google.com/recaptcha/api.js?onload=CaptchaCallback&render=explicit&hl=en"'
const address = "https://yandex.ru/map-widget/v1/?um=constructor%3Acc1b87035ca095a9ef872ed3aedd45f25661369f196e81f509be03123aa1f2a0&amp;source=constructor"
const insert = '<div class="spinner-border text-success abs" role="status" id="spinner"></div>'
const toggleMap = document.querySelector('.show-map')
const mapIframe = `<iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ab3f34df4b73ca051e487b563c7231d821b0546458daa73ff52368fda04027242&amp;source=constructor" width="100%" height="530" frameborder="0"></iframe>`
let flag = false
const getScript = (url,as=false)=>{
        const js_script = document.createElement('script')
        js_script.type = "text/javascript"
        js_script.src = url
        if(!flag){
        document.getElementsByTagName('head')[0].appendChild(js_script)
            flag = true
        }

    }
let topMenu = document.querySelector('#menu_header')
const JSButton = () => {
    let btn = document.querySelectorAll('.js-button')
    btn.forEach((item)=>{
        item.addEventListener('click',(e)=>{
            e.preventDefault()
            getScript(captcha,false)
        })
    })
}
const scrollForm = () => {
    let form_static = document.querySelector('.form-static')
     let scrolling = true
    document.addEventListener('scroll', () => {
        if (scrolling && form_static){
            let formPos = form_static.getBoundingClientRect().top
            let current = document.documentElement.scrollTop
            if (current >= formPos) {
                getScript(captcha, false)
            }
        }
    })

}


const ShowMap = () => {
    if(toggleMap){
        toggleMap.addEventListener('click',(e) => {
            e.preventDefault()
            let mapBox = new WinBox("Адрес офиса на карте", {
                url: "https://go.2gis.com/ygzjt7",
                x: "center",
                y: "10%",
                height:'70%'
            });
        mapBox.dom.classList.add('green')
        // mapBox.dom.querySelector('.wb-body').insertAdjacentHTML('afterbegin',insert)
        document.querySelector('.wb-full').style.display="none"
        })
    }
}

const Footer = () => {
    let items = topMenu.querySelectorAll('.listItem')
    let containerFirst = document.querySelector('#item-first')
    let containerSecond = document.querySelector('#item-second')
    let itemsArray = []
    for(let i of items){
        if(!i.closest('.subMenu')){
            itemsArray.push(i.querySelector('a'))
        }
    }
    let division = itemsArray.length%2 == 0 ? itemsArray.length / 2 : itemsArray.length / 2 + 1
    let arr1 = itemsArray.slice(0,division)
    let arr2 = itemsArray.slice((itemsArray.length - arr1.length)*-1)
    containerFirst.innerHTML = arr1.map(i=>i.outerHTML).join('')
    containerSecond.innerHTML = arr2.map(i=>i.outerHTML).join('')

}

const DropDownMenu = () =>{
    const submenuItems = topMenu.querySelectorAll('.submenuItem')
    for(let item of submenuItems){
        item.addEventListener('mouseenter',(ev) => {
            let menu = ev.currentTarget.querySelector('ul')
            slideDown(menu,400)
        })
        item.addEventListener('mouseleave',(ev) => {
            let menu = ev.currentTarget.querySelector('ul')
            slideUp(menu,400)
        })
    }
}



export  {JSButton,Footer,DropDownMenu}