import forms from './modules/form.js'
import * as opt from "./modules/optimize.js"
import Sliders from './modules/sliders.js'
import Pagination from './modules/pagination.js'
import  phoneMask from "./modules/mask.js"
import  RunFunctions from './modules/pure.js'

document.addEventListener("DOMContentLoaded", () => {

RunFunctions(opt)
forms()
phoneMask()
Sliders()
Pagination('.news-index',true,false)
Pagination('.gallery-index',false,true)
})
