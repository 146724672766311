import { Parsing } from "./pure.js";
import  observer  from "./imageLoad.js";
import News from './news.js'
const Pagination = (cl,modal=false,sort=false) => {
      window.onpopstate = (e) => {
      window.location = location.href;
    }
let newsIndex = document.querySelector('.news-index')
let itemLong = document.querySelector('.item-long')
let hasNewsIndex = () => {
    return newsIndex  !== null && itemLong !== null
}
    if(location.search && hasNewsIndex()){

        document.querySelector('.news-index').querySelector('.item-long').classList.remove('item-long')

    }
    let positionUpdate = (selector) => {
        const scrollTarget = document.querySelector(selector)
        const elementPosition = scrollTarget.getBoundingClientRect().top - window.pageYOffset
        window.scrollBy({
            top: elementPosition,
            behavior: 'smooth'
        })
    }

    let PrivateNav ='.page-link'

    const paginatorPage = (listBox) => {
            let newsLinks = document.querySelectorAll(PrivateNav)
            if(newsLinks !== null){

                newsLinks.forEach(link => {
                    link.addEventListener('click',(e)=>{
                        e.preventDefault()
                        let el = e.target
                        getData(el,listBox)

                    })
                })
            }
       }


    // Функция пагинации
    const getData = async (el,selector) => {
    const nextPage = await fetch(el.href)
        .then(data => data.text())
        .then(page => {
                Parsing(page,selector)
                document.querySelector(selector).classList.add('updated')
                let new_href = el.href.split('?')[1]
                if(new_href !== undefined){
                      window.history.pushState(
                            null,
                            document.title,
                            `${window.location.pathname}?${new_href}`
                          )
                      }else{
                        window.history.pushState(
                                null,
                                document.title,
                                `${window.location.pathname}`
                              )
                    }
                if(location.search && hasNewsIndex()){
                        document.querySelector('.news-index').querySelector('.item-long').classList.remove('item-long')
                    }
                paginatorPage(selector)

                }).then(()=>{
                    setTimeout(() => {
                       document.querySelector(selector).classList.remove('updated')
                    }, 1000);
                        positionUpdate(selector)
                        observer()
                        if(modal){News()}
                        if(sort){
                        sortData(selector)
                        changeTemplate('.gallery-index')
                        }
                    })


        return nextPage
        }
    if(document.querySelector(cl)){
    paginatorPage(cl)
    }



    const sortData = (listbox) => {
        let gallerySorter = document.querySelector('.gallerySorter')
        if(gallerySorter){
            let gallerySorterLinks = gallerySorter.querySelectorAll('a')
            gallerySorterLinks.forEach(item=>{
                item.addEventListener('click',(ev) => {
                    ev.preventDefault()
                    fetchSort(ev.target,listbox)
                })
            })
        }
    }
    const fetchSort = async (url,listbox) => {
        const result = await fetch(url.href)
        .then(result => result.text())
        .then(data=>{
                    Parsing(data,listbox)
                    document.querySelector(listbox).classList.add('updated')
                    let new_href = url.href.split('?')[1]
                    if(new_href !== undefined){
                        window.history.pushState(
                                null,
                                document.title,
                                `${window.location.pathname}?${new_href}`
                            )
                        }else{
                            window.history.pushState(
                                    null,
                                    document.title,
                                    `${window.location.pathname}`
                                )
                        }
                }).then(()=>{
                    setTimeout(() => {
                        document.querySelector(listbox).classList.remove('updated')
                    }, 1000);
                    observer()
                    sortData(listbox)
                    changeTemplate(listbox)
                    paginatorPage(listbox)
                })
    }
            const  changeTemplate = async (selector) => {
                let templateBtn = document.querySelectorAll('.template__item')
                for(let item of templateBtn){
                    item.addEventListener('click',(e)=>{
                        let el = e.currentTarget
                        let view = el.dataset.view
                        document.cookie = `store_item=${view};path=/`
                        fetchTemplate(location.href)
                        .then(data=>{
                            Parsing(data,selector)
                            document.querySelector(selector).classList.add('updated')
                        }).then(()=>{
                            setTimeout(() => {
                                document.querySelector(selector).classList.remove('updated')
                            }, 1000);
                            observer()
                            sortData(selector)
                            changeTemplate(selector)
                            paginatorPage(selector)
                        })
                    })
                }
             }
        const fetchTemplate = async(url) => {
            let res = await fetch(url)
                return res.text()
        }

        if(sort){
        sortData('.gallery-index')
        changeTemplate('.gallery-index')
        }

}

export default Pagination