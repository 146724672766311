const observer = () => {
	let images = document.querySelectorAll('.obs-img')
	const options = {
		delay:0,
		root:null,
		rootMargin:'0px',
		threshold:0.1,
	}
	function handlImg(myImg,_){
		myImg.forEach(myImgSingle=>{
			if(myImgSingle.intersectionRatio > 0){
				loadImage(myImgSingle.target)
			}
		})
	}
	function loadImage(image) {
		image.src = image.getAttribute('data')
		image.classList.remove('obs-img')
		if(image.nextElementSibling){
			image.nextElementSibling.remove()
		}
	}
	const observer = new IntersectionObserver(handlImg,options)
		images.forEach(img => {
		observer.observe(img)
		})

}

export default observer